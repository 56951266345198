// @ts-strict-ignore
import type { ReactNode } from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { FloatingLabelInput } from "@aviary/components/FloatingLabelInput";
import { COUNTRIES } from "@aviary/components/PhoneInput/CountrySelector/Countries";
import { CountrySelector } from "@aviary/components/PhoneInput/CountrySelector/CountrySelector";
import {
  getCleanNumber,
  isValidPhoneNumber,
} from "@aviary/components/PhoneInput/validatePhoneNumber";
import { useIsDS4Enabled } from "@aviary/hooks/useIsDS4Enabled";
import { l } from "@aviary/locales/i18n";
import type { PopperPositionType } from "@aviary/types/popperPositions";

import { DeprecatedPhoneInput } from "./DeprecatedPhoneInput";

import * as styles from "./PhoneInput.styles";

const MIN_NUMBERS_MASK = "(###) ### #####";
const DEFAULT_COUNTRY_NAME = "US";

const getCountryFromCode = (countryCode: string) =>
  COUNTRIES.find(({ code }) => code === countryCode);

export const removePlusSign = (phoneNumber: string) => phoneNumber.replace("+", "");

export const getPhoneWithoutCountryCode = value => {
  const cleanNumber = getCleanNumber(value);
  const country = getCountryFromNumber(value);
  if (country) {
    const dialCodeRegExp = new RegExp(`^` + country.dialCode);

    return cleanNumber.replace(dialCodeRegExp, "");
  }

  return cleanNumber;
};

const getCountryFromNumber = (value, preferredCountryCode = DEFAULT_COUNTRY_NAME) => {
  const justDigits = getCleanNumber(value);
  if (justDigits === "" || !value.startsWith("+")) {
    return COUNTRIES.find(({ code }) => code === preferredCountryCode);
  }

  const countriesWithCode = COUNTRIES.filter(({ dialCode }) => justDigits.startsWith(dialCode));
  if (countriesWithCode.length > 1 && preferredCountryCode) {
    const preferredCountry = countriesWithCode.find(({ code }) => code === preferredCountryCode);
    if (preferredCountry) {
      return preferredCountry;
    }
  }

  return countriesWithCode?.length > 0
    ? countriesWithCode[0]
    : COUNTRIES.find(({ code }) => code === preferredCountryCode);
};

export type PreferredCountries = "CA" | "US";

interface Props {
  /**
   * Unique field id
   */
  id: string;
  /**
   * Label for the phone input
   */
  label: string;
  /**
   * Input control value
   */
  value: string;
  /**
   * Callback from the HTML element on change
   */
  onChange: (formattedPhoneNumber: string) => void;
  /**
   * Input control name
   */
  name?: string;
  /**
   * Makes the field required
   *
   * @default false
   */
  required?: boolean;
  /**
   * Array of field-level errors
   */
  errors?: ReactNode[];
  /**
   * Text shown below the input to describe it's function
   */
  descriptiveText?: ReactNode;
  /**
   * Used when two countries have same number ("CA" or "US"), will update the flag icon
   * @default "US"
   */
  preferredCountryCode?: PreferredCountries;
  /**
   * Position direction that the content will appear
   * @default "bottom-start"
   */
  dropdownPlacement?: PopperPositionType;
  /**
   * Disables input
   * @default false
   */
  disabled?: boolean;
}

/**
 * Documentation:
 * https://aviary.docs.fullscript.cloud/components/inputs/PhoneInput
 */
const PhoneInput = (props: Props) => {
  const {
    id,
    label,
    value,
    onChange,
    errors,
    required,
    descriptiveText,
    preferredCountryCode = DEFAULT_COUNTRY_NAME,
    dropdownPlacement = "bottom-start",
    disabled = false,
    ...rest
  } = props;

  const { t } = useTranslation();
  const [selectedCountry, setSelectedCode] = useState(
    getCountryFromNumber(value, preferredCountryCode)
  );

  const [isInputActive, setIsInputActive] = useState(false);

  useEffect(() => {
    setSelectedCode(getCountryFromNumber(value, preferredCountryCode));
  }, [value, preferredCountryCode]);

  const isDS4Enabled = useIsDS4Enabled();

  if (!isDS4Enabled)
    return (
      <DeprecatedPhoneInput {...{ preferredCountryCode, dropdownPlacement, disabled, ...props }} />
    );

  const phoneInputWrapperStyles = [
    styles.phoneInputWrapper.base,
    disabled && styles.phoneInputWrapper.disabled,
  ];

  const phoneInputStyles = [styles.phoneInput, isInputActive && styles.focused];

  const phoneNumber = getPhoneWithoutCountryCode(value);
  const inputErrors = errors?.length > 0 ? errors : [];

  const enableActiveState = () => {
    if (disabled) return null;

    return setIsInputActive(true);
  };

  const disableActiveState = () => {
    if (disabled) return null;

    return setIsInputActive(false);
  };

  const handleOnChange = e => {
    const cleanNum = getCleanNumber(e.target.value);
    onChange(`+${selectedCountry.dialCode}${cleanNum}`);
  };

  const handleSelectedCountry = countryCode => {
    const country = getCountryFromCode(countryCode);
    setSelectedCode(country);

    onChange(`+${country.dialCode}${phoneNumber}`);
  };

  const hasInvalidPhoneNumber =
    value && phoneNumber.length !== 0 && !isValidPhoneNumber(phoneNumber);

  const conditionalPaddingStyles = [
    styles.inputWrapperOverrides,
    selectedCountry?.dialCode?.length === 1 && styles.shortCode,
  ];

  return (
    <div css={phoneInputWrapperStyles} onBlur={() => disableActiveState()}>
      <div css={phoneInputStyles}>
        <div css={styles.countrySelector}>
          <CountrySelector
            selectedCode={selectedCountry?.code}
            onSelect={handleSelectedCountry}
            dropdownPlacement={dropdownPlacement}
            disabled={disabled}
            onFocus={() => enableActiveState()}
          />
        </div>
        <FloatingLabelInput
          id={id}
          label={label}
          type="tel"
          mask={MIN_NUMBERS_MASK}
          value={phoneNumber}
          handleChange={handleOnChange}
          errors={[...inputErrors, hasInvalidPhoneNumber && t(l.aviary.phoneInput.tooShort)]}
          inputMode="tel"
          required={required}
          descriptiveText={descriptiveText}
          inputStyles={conditionalPaddingStyles}
          disabled={disabled}
          {...rest}
        />
      </div>
    </div>
  );
};

export { PhoneInput };
